<template>
    <div class="play-gb">
        <div class="pg-cont" v-if="flow && flow.status">
            <div id="gbPlay"></div>
        </div>
        <div class="pg-cont-offline" v-else>离线 </div>
        <div class="pg-footer" v-if="detail && detail.cloud_device">
            <div v-if="flow">
                <span>流状态：{{ flow.status ?'在线':'离线' }}</span> 

                <span v-if="channles.length > 0">
                    <a-select  
                        style="width: 200px;margin-left:12px;" 
                        v-model:value="index" 
                        @change="changeChannelsGbId">
                        <a-select-option v-for="(item,index) in channles" :key="index" :value="index">
                            {{item.gbId}}
                        </a-select-option>
                    </a-select>
                </span>
            </div>
            
            <div v-if="detail.cloud_device.state == 'locked' || detail.cloud_device.state == 'notReg'">
                <a-tooltip title="由于设备或通道不在线,无法执行启用拉流操作">
                    <a-button>启动拉流</a-button>
                </a-tooltip>
            </div>
            <div v-else>
                <a-button type="primary" @click="pullFlow('start')" v-if="!flow || !flow.status">启动拉流</a-button>
                <a-button type="primary" @click="pullFlow('stop')" v-else>停止拉流</a-button>
            </div>
        </div>
    </div>    
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import liveModel from '@/api/live'
import { QNRTPlayer } from "qn-rtplayer-web";
import { onBeforeRouteLeave } from 'vue-router';
export default {
    name:"com-play-gb",
    props:{
        webrtc:{    //播放地址
            type:String
        },
        deviceId:{      //设备id
            type:[String,Number]
        },
        channleId:{     //通道ID
            type:String
        }
    },
    setup(props,context) {
        const state =reactive({
            detail:null,    //设备信息
            flow:null,      //流信息
            webrtc_2:"",    //当组件没有传入webrtc时，使用此地址播放
            player:null,    //七牛云播放对象
            channles:[],
            index:0,        //当前选择的通道
        })
        getDeviceDetaiil()

        //监听当前绑定的值的数据变化 主要用户初始化监听v-model传入的值
        watch(() => props.channleId,(value) => {
                pullFlow('stop',state.channles[state.index].gbId)
                state.channles.forEach((item,index)=>{
                    if( item.gbId == value ){
                        state.index = index
                        pullFlow('start')
                    }
                })
            }
        )

        //获取设备信息
        function getDeviceDetaiil(){
            if( !props.deviceId ) return
            liveModel.getGbDeviceDetail({id:props.deviceId}).then(res=>{
                state.detail = res
                if( res.cloud_device.type == 2){
                    liveModel.getChannel(props.deviceId).then(res1=>{
                        let arr = res1.items
                        if( res1.items.length > 0 ){
                            for (let i = 0; i < arr.length; i++) {
                                if( arr[i].gbId == props.channleId ) {
                                    state.webrtc_2 = arr[i].webrtc
                                    state.index = i
                                    break
                                }
                            }
                        }
                        state.channles = arr
                        getFlow(props.channleId || state.channles[0].gbId)
                    })
                }else{
                    state.webrtc_2 = state.detail.user_device.webrtc
                    getFlow(null)
                }
            })
        }

        //获取流信息
        function getFlow(channleId){
            liveModel.getStreamInfo(props.deviceId,channleId).then(res=>{
                state.flow = res
                if( res.status ) playDevice()
            })
        }

        //启动或停止拉流操作
        function pullFlow(type,channel_id=null){
            if( !channel_id ) channel_id = props.channleId
            //启动拉流
            if( type == 'start' ){
                liveModel.startOrStopPullStreams('start',props.deviceId,channel_id).then(()=>{
                    state.flow.status = true
                    playDevice()
                })
                return
            }

            //停止拉流
            if( type == 'stop' ){
                if( !state.detail || !state.player) return

                //七牛播放器停止并释放
                state.player.stop()
                state.player.release()
                state.player = null
                liveModel.startOrStopPullStreams('stop',props.deviceId,channel_id).then(()=>{
                    state.flow.status = false
                })
            }
        }

        function playDevice(){
            let timer = setTimeout(function(){
				clearTimeout(timer)
				let url = state.webrtc_2 ? state.webrtc_2 :props.webrtc
               
				// url = url.replace('447','1240')
				//七牛播放器
				const player = new QNRTPlayer();
				// 2. 初始化配置信息
				player.init({controls:true,playsinline:false});
				// 3. 传入播放地址及容器，开始播放
				player.play( url, document.querySelector('#gbPlay') )
				state.player = player
			},1000)
        }

        //切换国标id播放
        const changeChannelsGbId = (e)=>{
            context.emit("changeChannels",state.channles[e].gbId)
            state.webrtc_2 = state.channles[e].webrtc
            if( !state.player ){
				getFlow(state.channles[e].gbId)
				return
			}
			state.player.stop()
			state.player.release()
			state.player = null
            getFlow(state.channles[e].gbId)
        }

        onBeforeRouteLeave(()=>{
            pullFlow('stop')
        })

        return{
            ...toRefs(state),
            getFlow,
            pullFlow,
            playDevice,
            changeChannelsGbId
        }
    },
}
</script>
<style lang="scss" scoped>
.pg-cont{
    background: #000;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 370px;
    margin-bottom: 12px;
}
.pg-cont-offline{
    background: #000;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 350px;
    line-height: 350px;
    margin-bottom: 12px;
}
.pg-footer{
    display: flex;
    justify-content: space-between;
}
</style>